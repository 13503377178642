<template>
  <div class="modal-view-members">
    <div class="modal-view-members__container">
      <div class="modal-view-members__header">
        <h2 class="modal-view-members__title">
          {{ currentCardData.count_users }}
          {{
            setEndingCountUsers(currentCardData.count_users, "Участник", "Участника", "Участников")
          }}
        </h2>
        <i class="icon-cross" @click="hideModal"></i>
      </div>
      <div class="modal-view-members__search">
        <Widget
          type="string"
          placeholder="Поиск"
          :unique="'searchCardUsers'"
          v-model.trim="inputSearchValue"
        ></Widget>
        <i class="icon-search"></i>
      </div>
      <Widget v-if="loader" type="loader" />
      <ul v-else class="modal-view-members__list">
        <ViewMembersCard
          v-for="(item, index) in memberUsers"
          :key="index"
          :item="item"
          :currentCardData="currentCardData"
          :isAdmin="isAdmin"
          @askDeleteGroupsMemberUser="askDeleteGroupsMemberUser"
        />
        <li class="modal-view-members__item empty" v-if="!memberUsers.length">
          Нет результатов :(
        </li>
      </ul>
      <div
        v-if="!loader"
        class="modal-view-members__footer"
        :style="isAdmin ? 'border-top: 1px solid #E7E7FB' : 'border-top: none'"
      >
        <button class="modal-view-members__add" @click="showAddModal" type="button" v-if="isAdmin">
          Добавить участника
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { domain } from "@/globalVariables";
import Swal from "sweetalert2";
import httpClient from "@/api/client/httpClient";
const Widget = () => import("@/components/Widgets/Widget");
const ViewMembersCard = () => import("@/components/Structure/ModalStructure/ViewMembersCard");

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "delete-button",
    cancelButton: "cancel-button",
    popup: "popup-class",
    title: "title-class",
    content: "content-class",
    actions: "actions-class",
    icon: "icon-class"
  },
  buttonsStyling: false
});

export default {
  name: "ModalViewCardMembers",
  mixins: [],
  props: ["currentCardData", "isAdmin"],
  components: {
    ViewMembersCard,
    Widget
  },
  data() {
    return {
      memberUsers: [],
      inputSearchValue: "",
      hoverIndex: null,
      loader: true
    };
  },
  beforeDestroy() {
    this.$root.$off(`text:change_searchCardUsers`);
  },
  mounted() {
    this.getMembersUsers();
    this.$root.$on(`text:change_searchCardUsers`, (val) => {
      this.inputSearchValue = val;
      this.checkSearchResults();
    });
  },
  methods: {
    getMembersUsers() {
      this.hoverIndex = null;
      this.loader = true;
      httpClient({
        url: `/User/getItems`,
        method: "GET",
        params: {
          _count: 300,
          _search: this.inputSearchValue.length ? { full_name: this.inputSearchValue } : undefined,
          org_structure_items: JSON.stringify([
            { "org_structure_items.id": this.currentCardData.id }
          ]),
          _with: JSON.stringify(["org_structure_items", "Roles"])
        }
      })
        .then((response) => {
          this.memberUsers = response.data.data.items;
          this.currentCardData.count_users = response.data.data.items.length;
          this.loader = false;
          this.$root.$emit("updateStructureChart");
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    checkSearchResults() {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.getMembersUsers();
      }, 500);
    },
    setEndingCountUsers(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
    hideModal() {
      this.$emit("hideModalViewCardMembers");
    },
    showAddModal() {
      this.$emit("openModalAddViewCardMembers");
    },
    deleteGroupsMemberUser(id) {
      httpClient({
        url: `/OrgStructureItemUser/actionDelete/${id}`,
        method: "DELETE"
      })
        .then(() => {
          this.getMembersUsers();
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Участник успешно удален!`
          });

          this.$root.$emit("updateStructureChart");
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    askDeleteGroupsMemberUser(id) {
      swalWithBootstrapButtons
        .fire({
          title: "Удалить?",
          text: `Вы уверены, что хотите удалить данного участника?`,
          confirmButtonText: "Да"
        })
        .then((result) => {
          if (!result.dismiss) {
            this.deleteGroupsMemberUser(id);
          }
        });
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";

::-webkit-scrollbar {
  height: 5px;
  border-radius: 10px;
  overflow-x: auto;
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background: $color-contour;
  border-radius: 10px;
  width: 3px;
}

::-webkit-scrollbar-track {
  margin: 0;
}

.modal-view-members {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding-left: 10px;
  padding-right: 10px;

  background-color: rgba(184, 184, 203, 0.2);

  &__container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 360px;
    width: 100%;
    max-height: 80vh;
    padding: 20px 0;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);
    background-color: $color-white;
    border: 1px solid $color-contour;
    border-radius: 10px;
  }

  &__header {
    width: 100%;
    margin-bottom: 20px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon-cross {
      color: $color-text-secondary;
      font-size: 20px;
      cursor: pointer;
      transition: 0.2s ease;

      &:hover {
        color: $color-button-hover;
      }

      &:active {
        color: $color-button-click;
      }
    }
  }

  &__title {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: $color-text-accent;
  }

  &__search {
    position: relative;
    width: 100%;
    height: 36px;
    padding: 0 20px;
    margin-bottom: 15px;

    /deep/ input {
      padding-right: 30px;
    }

    .icon-search {
      display: block;
      position: absolute;
      top: 7px;
      right: 29px;
      font-size: 18px;
      color: $color-text-secondary;
      object-fit: cover;
    }
  }

  /deep/ {
    .loader-bg {
      margin-bottom: 15px;
    }
  }

  &__list {
    position: relative;
    width: 100%;
    max-height: 325px;
    overflow-y: scroll;
    list-style: none;
    padding: 0 0 10px 20px;
    margin: 0 0 2px;
    @media screen and (max-height: 650px) {
      max-height: 120px;
    }
    @media screen and (max-height: 400px) {
      max-height: 60px;
    }
  }

  &__footer {
    max-width: 321px;
    width: calc(100% - 40px);
    padding: 25px 0 0;
    margin-left: 20px;
    margin-right: 20px;
  }

  &__add {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-interactive;

    outline: none;
    border-radius: 4px;
    transition: all 0.2s ease;
    border: 1px solid $color-interactive;
    background-color: transparent;

    &:hover {
      background-color: $color-button-hover;
      color: $main-white;
    }

    &:active {
      background-color: $color-button-click;
      color: $main-white;
    }
  }
}

.empty {
  width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: $color-other-2;
}
</style>
